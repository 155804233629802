import { formatMoney } from 'accounting';

export const money = (value: string | number, decimals?: number) => {
  let result = formatMoney(Number(value), {
    symbol: '$',
    precision: decimals ?? 2,
  });

  result = result.replace('.00', '');
  return result;
};

export const queryStringToJSON = (query: string) => {
  const pairs = query.slice(1).split('&');

  const result = {};
  pairs.forEach((e) => {
    const pair = e.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });

  return JSON.parse(JSON.stringify(result));
};

export const capitalizeFirstLetter = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
};

export const toCamelCase = (str?: string) => {
  return str
    ? str
        .toLowerCase()
        .replace(/\s+(\w)?/gi, (_, letter) => letter.toUpperCase())
    : str;
};

export const toPascalCase = (str?: string) => {
  return `${str}`
    .toLowerCase()
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w*)/, 'g'),
      (_, $2, $3) => `${$2.toUpperCase() + $3}`
    )
    .replace(new RegExp(/\w/), (s) => s.toUpperCase());
};

export const toTitleCase = (str?: string) => {
  return `${str}`
    .toLowerCase()
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w*)/, 'g'),
      (_, $2, $3) => ` ${$2.toUpperCase() + $3}`
    )
    .replace(new RegExp(/\w/), (s) => s.toUpperCase());
};

export const hexToRgbA = (hex: string, alpha?: string) => {
  if (!hex) {
    return; // throw new Error('Bad Hex');
  }
  let _hex = hex[0] === '#' ? hex.substring(1) : hex;
  if ([3, 6].indexOf(_hex.length) === -1) {
    return; // throw new Error('Bad Hex');
  }

  // if shortcuts (#F00) -> set to normal (#FF0000)
  if (_hex.length === 3) {
    _hex = _hex
      .split('')
      .map((el) => `${el}+${el}`)
      .join('');
  }
  const r = parseInt(_hex.slice(0, 2), 16);
  const g = parseInt(_hex.slice(2, 4), 16);
  const b = parseInt(_hex.slice(4, 6), 16);
  if (alpha) {
    return `rgba(${r},${g},${b},${alpha})`;
  }
  return `rgb(${r},${g},${b})`;
};

const padZero = (str: string, len?: number) => {
  const _len = len || 2;
  const zeros = new Array(_len).join('0');
  return (zeros + str).slice(-_len);
};

export const invertHex = (hex: string) => {
  let _hex = hex;
  if (_hex.indexOf('#') === 0) {
    _hex = _hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (_hex.length === 3) {
    _hex = _hex[0] + _hex[0] + _hex[1] + _hex[1] + _hex[2] + _hex[2];
  }
  if (_hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  // invert color components
  const r = (255 - parseInt(_hex.slice(0, 2), 16)).toString(16);
  const g = (255 - parseInt(_hex.slice(2, 4), 16)).toString(16);
  const b = (255 - parseInt(_hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return `#${padZero(r)}${padZero(g)}${padZero(b)}`;
};

export const toValidUri = (inputString: string) => {
  const disallowedCharsRegex = /[\[\]@!$&'()*+,:;= ]/g;
  return encodeURIComponent(inputString.replace(disallowedCharsRegex, ''));
};

export const convertObjectFromJSON = <T>(json: string): T => {
  return JSON.parse(json);
};

export const removeSpecialCharacters = (str?: string) => {
  return str ? str.replace(/[ ](?=[ ])|[^0-9a-zA-Z ]+/gm, '') : str;
};

import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface TextFieldProps {
  label?: string;
  error?: string;
  errorLocation?: 'start' | 'end';
  variant?: 'standard' | 'outlined' | 'filled';
  rounded?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

type Props = TextFieldProps & React.HTMLProps<HTMLInputElement>;

export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      label,
      variant,
      rounded,
      error,
      errorLocation,
      startAdornment,
      endAdornment,
      onChange,
      ...props
    },
    ref
  ) => {
    const [isActive, setIsActive] = React.useState(false);

    const textFieldClassName = cn(
      styles.TextField,
      styles[`TextField_${variant}`],
      props.className,
      {
        [styles.TextField__active]: isActive,
        [styles.TextField__error]: !!error,
      }
    );

    const innerClassName = cn(
      styles.TextFieldInner,
      styles[`TextFieldInner_${variant}`],
      {
        [styles[`withStartAdornment`]]: !!startAdornment,
        [styles[`withEndAdornment`]]: !!endAdornment,
        [styles[`roundedTextInput`]]: rounded,
      }
    );

    const labelClassName = cn(
      styles.TextFieldLabel,
      styles[`TextFieldLabel_${variant}`],
      {
        [styles[`TextFieldLabel_active`]]: isActive,
        [styles[`withStartAdornment`]]: !!startAdornment,
        [styles[`withEndAdornment`]]: !!endAdornment,
      }
    );

    const inputClassName = cn({ [styles[`roundedTextInput`]]: rounded });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.value !== '');
      if (onChange) onChange(event);
    };

    return (
      <div className={textFieldClassName}>
        <div className={innerClassName}>
          {startAdornment && (
            <div className={styles.TextFieldAdornment}>{startAdornment}</div>
          )}
          <input
            {...props}
            ref={ref}
            name={name}
            className={inputClassName}
            onChange={handleChange}
          />
          {endAdornment && (
            <div className={styles.TextFieldAdornment}>{endAdornment}</div>
          )}
          <label htmlFor={name} className={labelClassName}>
            {label}
          </label>
        </div>

        {error && (
          <span
            className={cn(
              styles.TextFieldErrorMsg,
              styles[`Error_${errorLocation}`]
            )}
          >
            {error}
          </span>
        )}
      </div>
    );
  }
);

Input.defaultProps = {
  errorLocation: 'start',
  variant: 'standard',
};

import React, { useState, useEffect, useRef, useCallback } from 'react';
import cn from 'classnames';
import { scroller, Events } from 'react-scroll';
import { Link, SubNavigationItem } from '~/common/models';
import {
  Button,
  ButtonTypeEnum,
  SvgIcon,
} from '~/common/components/ui-elements';
import styles from './index.module.scss';
import { joinPath } from '~/common/utils';

interface Section {
  id: number;
  offsetTop: number;
  offsetHeight: number;
}

interface NavigationProps {
  items: SubNavigationItem[];
  navigationSticky?: boolean;
  enableReturnTop?: boolean;
  center?: boolean;
  backgroundColor?: string;
  navItemsContainerClass?: string;
  color?: string;
  ctaButton?: Link;
  ctaButtonStyle?: keyof typeof ButtonTypeEnum;
  prefixContent?: React.ReactNode;
}

let targetContentId = 0;
export const SubNavigation = React.memo<NavigationProps>(
  ({
    items,
    navigationSticky,
    enableReturnTop,
    center,
    backgroundColor,
    color,
    navItemsContainerClass,
    ctaButton,
    ctaButtonStyle,
    prefixContent,
  }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isSticky, setIsSticky] = useState<boolean>(false);
    const [isExpand, setIsExpand] = useState<boolean>(false);
    const [activeNavItem, setActiveNavItem] =
      useState<SubNavigationItem | null>(null);

    const handleClick = (nav: SubNavigationItem) => {
      targetContentId = nav.contentId;
      setActiveNavItem(nav);

      scroller.scrollTo(`cid-${nav.contentId}`, {
        smooth: true,
        offset: -85,
        ignoreCancelEvents: true,
      });
    };

    const replaceURLWithoutReloading = useCallback((newUrl: string) => {
      if (window.history && window.history.replaceState) {
        window.history.replaceState(
          null,
          '',
          joinPath(
            `/${window['pageUrlSegment'] || window.location.pathname}`,
            window.location.search.includes('utm') ||
              window.location.search.includes('gclid')
              ? joinPath(newUrl, window.location.search)
              : newUrl
          )
        );
      }
    }, []);

    const checkScrollPosition = (sections: Section[]) => {
      if (isExpand) return;
      if (targetContentId !== 0) return;

      let activeLinkId = 0;
      const pageYOffset = window.scrollY;

      for (const section of sections) {
        const sectionTop = section.offsetTop - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (pageYOffset >= sectionTop && pageYOffset < sectionBottom) {
          activeLinkId = section.id;
          break;
        }
      }

      const navItem = items.find((x) => x.contentId === activeLinkId);
      if (!!navItem) {
        setActiveNavItem(navItem);
      }

      if (containerRef.current && sections.length > 0) {
        const firstSection = sections[0];
        const stickOnTop = firstSection.offsetTop - 100 <= pageYOffset;
        setIsSticky(navigationSticky === true && stickOnTop);
      }
    };

    const getTraverseOffsetTop = (element: HTMLElement) => {
      let totalOffsetTop = 0;
      let nextElement: HTMLElement = element;

      while (nextElement) {
        totalOffsetTop += nextElement.offsetTop;
        nextElement = nextElement.offsetParent as HTMLElement;
      }

      return totalOffsetTop;
    };

    const getSections = () => {
      const sections: Section[] = [];
      if (typeof document !== 'undefined') {
        for (const el of document.querySelectorAll('[id^="cid-"]')) {
          const divEl = el as HTMLElement;
          sections.push({
            id: Number(el.id.split('-')[1]),
            offsetHeight: divEl.offsetHeight,
            offsetTop: getTraverseOffsetTop(divEl),
          });
        }
      }
      return sections;
    };

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        if (window.location) {
          const pathnames = window.location.pathname.split('/');
          const lastPath = pathnames[pathnames.length - 1];
          if (lastPath) {
            const navItem = items.find((x) => x.urlSubPath === lastPath);
            if (!!navItem) {
              handleClick(navItem);
            }
          }
        }
      }, 1000);

      return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
      window.addEventListener('scroll', () => {
        checkScrollPosition(getSections());
      });

      return () => {
        window.removeEventListener('scroll', () =>
          checkScrollPosition(getSections())
        );
      };
    }, []);

    useEffect(() => {
      Events.scrollEvent.register('end', () => {
        targetContentId = 0;
      });

      return () => {
        Events.scrollEvent.remove('end');
      };
    }, []);

    useEffect(() => {
      if (!!activeNavItem) {
        let subPath;
        if (!!activeNavItem.urlSubPath) {
          subPath = activeNavItem.urlSubPath;
        } else {
          subPath = activeNavItem.displayText.toLowerCase().replace(/\s/g, '-');
        }
        replaceURLWithoutReloading(subPath);
      } else {
        replaceURLWithoutReloading('');
      }
    }, [activeNavItem]);

    return (
      <>
        <div
          key={1}
          ref={containerRef}
          className={cn(styles.navigation, {
            [styles.navSticky]: isSticky,
            [styles.navExpand]: isExpand,
          })}
          style={{ backgroundColor }}
        >
          <div className="container">
            <div className={styles.navWrapper}>
              {prefixContent}
              <ul
                className={cn(styles.navItems, navItemsContainerClass, {
                  [styles.navItems_center]: center,
                })}
              >
                {items.map((item, index) => (
                  <li
                    key={index}
                    className={cn(styles.navItem, {
                      [styles.isActive]:
                        item.contentId === activeNavItem?.contentId,
                    })}
                  >
                    <a
                      href={item.urlSubPath}
                      onClick={(e) => {
                        e.preventDefault();
                        handleClick(item);
                      }}
                      style={{ color }}
                    >
                      {item.displayText}
                    </a>
                  </li>
                ))}

                {enableReturnTop && (
                  <li className={styles.navItem}>
                    <a
                      href="#"
                      style={{ color }}
                      className={styles.returnTop}
                      onClick={(e) => {
                        e.preventDefault();
                        window.scroll({ top: 0, behavior: 'smooth' });
                      }}
                    >
                      <span>TOP</span>
                      <SvgIcon type="chevronUp" color="#FFF" size={1.2} />
                    </a>
                  </li>
                )}
              </ul>

              {ctaButton && (
                <div className={styles.ctaButton}>
                  <Button
                    onClick={() => window.open(ctaButton.url, ctaButton.target)}
                    type={ctaButtonStyle}
                    buttonSize="medium"
                  >
                    {ctaButton.name}
                  </Button>
                </div>
              )}

              <div
                className={styles.downArrow}
                onClick={() => setIsExpand(!isExpand)}
              >
                <SvgIcon
                  type={isExpand ? 'close' : 'chevronDown'}
                  color={color}
                  size={1.25}
                />
              </div>
            </div>
          </div>
        </div>

        {isExpand && (
          <div key={2} className={styles.mobileNavItems}>
            {items.map((item, index) => (
              <div
                key={index}
                className={styles.navItemMobile}
                onClick={() => {
                  setIsExpand(false);
                  handleClick(item);
                }}
              >
                {item.displayText}
              </div>
            ))}

            {ctaButton && (
              <div className={styles.ctaButtonMobile}>
                <Button
                  block
                  onClick={() => window.open(ctaButton.url, ctaButton.target)}
                  type={ctaButtonStyle}
                  buttonSize="medium"
                >
                  {ctaButton.name}
                </Button>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
);
